<template>
  <medical-history-template
    :other-medical-conditions="otherMedicalConditions"
    :medical-conditions="medicalConditions"
    :medical-conditions-visible="medicalConditionsVisible"
    :under-medications="underMedications"
    :medications-used="medicationsUsed"
    :medications-used-visible="medicationsUsedVisible"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import MedicalHistoryTemplate from '@/modules/questionnaire/components/steps/common/medical-background/medical-history/MedicalHistoryTemplate';

import { makeStep } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import { yes, no } from '@/modules/questionnaire/api/helpers';

const { field, requiredField } = fieldBuilder;

const FIELDS = [
  requiredField('otherMedicalConditions'),
  field('medicalConditions'),
  requiredField('underMedications'),
  field('medicationsUsed')
];

export default {
  name: 'MedicalHistory',
  components: { MedicalHistoryTemplate },
  mixins: [makeStep(FIELDS)],
  computed: {
    medicalConditionsVisible() {
      return yes(this.otherMedicalConditions);
    },
    medicationsUsedVisible() {
      return yes(this.underMedications);
    }
  },
  watch: {
    otherMedicalConditions(newValue) {
      if (yes(newValue)) {
        return;
      }

      this.medicalConditions = '';

      if (no(this.underMedications)) {
        this.showNextStep();
      }
    },
    underMedications(newValue) {
      if (yes(newValue)) {
        return;
      }

      this.medicationsUsed = '';

      if (no(this.otherMedicalConditions)) {
        this.showNextStep();
      }
    }
  },
  methods: {
    isFieldVisible(fieldName) {
      if (fieldName === 'medicalConditions') {
        return this.medicalConditionsVisible;
      }

      if (fieldName === 'medicationsUsed') {
        return this.medicationsUsedVisible;
      }

      return true;
    }
  }
};
</script>
